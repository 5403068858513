<template>
<div class="b2b-main-container">
  <BlackHeader
    :isSidebarBtnShown="isSidebarBtnShown"
    @toggleSidebar="toggleSidebar"
  />
  <B2BSidebar
    :isOpened="isSidebarOpen"
    :isCloseBtnShown="isSidebarCloseBtnShown"
    :options="sidebarOptions"
    @closeSidebar="closeSidebarAfterNavigation"
  />
  <div class="b2b-routes">
    <router-view></router-view>
  </div>
</div>
</template>

<script>
import BlackHeader from './BlackHeader.vue'
import B2BSidebar from './B2BSidebar.vue'

export default {
  components: {
    BlackHeader,
    B2BSidebar,
  },
  data() {
    return {
      appDiv: null,
      isSidebarOpen: true,
      isSidebarBtnShown: false,
      isSidebarCloseBtnShown: false,
      sidebarOptions: {
        'Members': 'B2BMembers',
        'Billing': 'B2BBillings',
        'Settings': 'B2BSettings',
      }
    }
  },
  created() {
    this.appDiv = document.getElementById('app')
  },
  watch: {
    'appDiv.offsetWidth'(newValue, _) {
      if (newValue <= 1024) {
        this.isSidebarOpen = false
        this.isSidebarBtnShown = true
        this.isSidebarCloseBtnShown = true

        return
      }

      this.isSidebarOpen = true
      this.isSidebarBtnShown = false
      this.isSidebarCloseBtnShown = false
    }
  },
  methods: {
    toggleSidebar() {
      this.isSidebarOpen = !this.isSidebarOpen
    },
    closeSidebarAfterNavigation() {
      if (this.appDiv.offsetWidth <= 1024) this.toggleSidebar()
    },
  },
}
</script>

<style lang="scss">
@import '../../assets/styleVars.scss';

.b2b-main-container {
  width: 100%;
  height: 100%;
  background-color: $mainBackgroundColor;
  overflow: hidden;

  .b2b-routes {
    width: calc(100% - 240px);
    height: 100%;
    margin-left: 240px;
    margin-top: 50px;
    display: flex;
    justify-content: center;
  }
}

@media (max-width: 1024px) {
  .b2b-main-container {
    .b2b-routes {
      width: 100%;
      margin-left: 0;
    }
  }
}
</style>