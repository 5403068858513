<template>
  <div
    class="sidebar"
    :class="{ 'sidebar-opened': isOpened }"
  >
    <div
      class="b2b-sidebar-element"
      :class="{ 'active-element': $route.name === value }"
      v-for="(value, key, index) in options"
      @click="handleSidebarNavigation(value)"
      :key="index"
    >
      {{key}}
    </div>
  </div>
</template>

<script>
export default {
  props: [
    'options',
    'isOpened',
    'isCloseBtnShown',
  ],
  data() {
    return {
      activeOptions: [],
    }
  },
  methods: {
    handleSidebarNavigation(routeName) {
      this.$emit('closeSidebar')
      this.$router.push({ name: routeName })
    },
  }
}
</script>

<style lang="scss" scoped>
  @import '../../assets/styleVars.scss';

  .sidebar {
    width: 0;
    height: calc(100% - 64px);
    margin-top: 64px;
    position: absolute;
    top: 0;
    left: 0;
    background-color: $mainBackgroundColor;
    overflow: hidden;
    z-index: 200;
    transition: all 0.2s;
    box-sizing: border-box;
    padding-top: 50px;

    .b2b-sidebar-element {
      width: 100%;
      height: 52px;
      position: relative;
      display: flex;
      align-items: center;
      box-sizing: border-box;
      padding-left: 24px;
      font-size: $fontSizeStandart;
      font-weight: $boldFont;
      color: $fontColorSidebarGray;
      cursor: pointer;
    }

    .active-element {
      color: $fontColorBlack;
    }
  }

  .sidebar-opened {
    width: 240px;
  }

  @media (max-width: 1024px) {
    .sidebar-opened {
      box-shadow: 2px 0 3px 0px #a09c9c;
    }
  }
</style>